<template>
    <about-subpage :charName="'jaar'">
        <template v-slot:content>
            <p class="mb-2">Character designs for</p>
            <h1 class="title">Captain/Granny Jaar</h1>
            <img @click="open" class="open" src="@/assets/img/character-sheets/Jaar.jpg" />
            <img @click="open" class="open" src="@/assets/img/character-sheets/Jaar-expressions.jpg" />

            <h2 class="subtitle mt-5">Older pre-production art</h2>
            <p>
                Early art tests trying out various styles and character designs
            </p>
            <img @click="open" class="open" src="@/assets/img/preproduction/preproduction-jaar.jpg"
            />
            <img @click="open" class="open" src="@/assets/img/preproduction/preproduction-test-scene.jpg"
            />
        </template>
    </about-subpage>
</template>

<script>
import AboutSubpage from "@/components/AboutSubpage.vue";

export default {
    name: "Jaar",
    components: { AboutSubpage },
    methods: {
        open(event) {
            window.open(event.target.src, '_blank')
        },
    }
};
</script>
